import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData, humanize, investmentData } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsers } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import {
  AccessId,
  agreementStatus,
  billingFrequencyEnums,
  defaultSignatory,
} from "constants/ConstantFields"

const DistributorAgreement = () => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [agreementFormState, setAgreementFormState] = useState({})
  const [users, setUsers] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [file, setFile] = useState(null)
  const [selectedAgreement, setSelectedAgreement] = useState({})
  const [isAgreementExists, setIsAgreementExists] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [agreementDocUrl, setAgreementDocUrl] = useState("")
  const [sendForSignModal, setSendForSignModal] = useState(false)
  const [consent, setConsent] = useState(false)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [userFilter, setUserFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState({})
  const [finalSelectedRows, setFinalSelectedRows] = useState([])

  const [cancelConsent, setCancelConsent] = useState(false)
  const [cancelAgreementModal, setCancelAgreementModal] = useState(false)

  const [billingFrequencyModal, setBillingFrequencyModal] = useState(false)
  const [selectedBillingFrequency, setSelectedBillingFrequency] = useState("")

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    statusFilter?.id ? `&status=${statusFilter.id}` : ""
  }`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/distributor-agreement?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (response) {
        setOrders(response.data?.data || response.data)
        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    const usersListRes = await distributorUsers()
    const usersList = usersListRes?.data?.data || usersListRes?.data

    if (usersList) {
      const distributors = usersList.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
  }, [])

  useEffect(async () => {
    masterData()
  }, [limit, skip, statusFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "status":
        setStatusFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }
  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const handleSignActionClick = item => {
    setSelectedAgreement(item)
    setSendForSignModal(true)
    setConsent(false)
  }
  const toggleSendForSignModal = () => {
    setSendForSignModal(!sendForSignModal)
  }
  const toggleBillingFrequencyModal = () => {
    setBillingFrequencyModal(!billingFrequencyModal)
  }

  const digioResData = row => {
    const data =
      row.webhook_data?.payload?.document?.signing_parties ||
      row.signing_request_data?.signing_parties ||
      []
    const obj = {
      signer_one: {
        name: "",
        email: "",
        status: "",
        sign_type: "",
      },
      signer_two: {
        name: "",
        email: "",
        status: "",
        sign_type: "",
      },
    }

    if (data.length) {
      for (let item of data) {
        if (item.identifier.toLowerCase() == row.distributor.email) {
          obj.signer_two.sign_type = item.signature_type.toUpperCase()
          obj.signer_two.email = item.identifier
          obj.signer_two.name = item.name
          obj.signer_two.status = item.status
        } else {
          obj.signer_one.sign_type = item.signature_type.toUpperCase()
          obj.signer_one.email = item.identifier
          obj.signer_one.name = item.name
          obj.signer_one.status = item.status
        }
      }
    }
    return obj
  }

  const toggleCancelAgreementModal = () => {
    setCancelAgreementModal(!cancelAgreementModal)
  }
  const handleCancelActionClick = item => {
    setSelectedAgreement(item)
    setCancelConsent(false)
    toggleCancelAgreementModal()
  }

  const handleCancelAgreement = async (e, values) => {
    try {
      setLoading(true)
      const res = await axiosInstance.delete(
        `/digio-signing-request/${selectedAgreement.digio_document_id}?type=distributor_agreement`
      )
      if (res) {
        toast.success("Sign request cancelled successfully")
        await masterData()
        toggleCancelAgreementModal()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  const AgreementColumns = download => {
    const cols = []
    if (!download) {
      cols.push(
        {
          dataField: "action",
          isDummyField: true,
          text: "Edit",
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, order) => (
            <>
              <div className="d-flex gap-3">
                {accessRestriction >= 3 ||
                accessRestriction === "SuperAdmin" ? (
                  <>
                    {order.status == "CREATED" ||
                    order.status == "NOT_CREATED" ||
                    order.status == "CANCELLED" ? (
                      <Link
                        to="#"
                        className="text-success"
                        onClick={() => handleOrderClick(order)}
                      >
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id="edittooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="edittooltip"
                        >
                          Edit
                        </UncontrolledTooltip>
                      </Link>
                    ) : (
                      "NA"
                    )}
                  </>
                ) : (
                  "NA"
                )}
                {/* {accessRestriction === "SuperAdmin" ? (
              <Link
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )} */}
              </div>
            </>
          ),
        },
        {
          dataField: "",
          text: "Action",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) =>
            row.status == "CREATED" && row.unsigned_doc_url ? (
              <Link to="#" className="text-primary">
                <Button
                  type="button"
                  color="primary"
                  className="btn-md btn-rounded"
                  onClick={() => handleSignActionClick(row)}
                >
                  Sign
                </Button>
              </Link>
            ) : (
              <>
                {row.status == "SENT_FOR_SIGN" ? (
                  <Link to="#" className="text-primary">
                    <Button
                      type="button"
                      color="danger"
                      className="btn-md btn-rounded"
                      onClick={() => handleCancelActionClick(row)}
                    >
                      Cancel
                    </Button>
                  </Link>
                ) : (
                  "NA"
                )}
              </>
            ),
        }
      )
    }
    cols.push(
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },
      { dataField: "distributor.email", text: "Email", sort: true },
      {
        dataField: "effective_date",
        text: "Effective Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row?.effective_date),
      },
      {
        dataField: "billing_frequency",
        text: "Billing Frequency",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          billingFrequencyEnums.find(obj => obj.enum == cellContent)?.name,
      },
      {
        dataField: "status",
        text: "Document Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = agreementStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "unsigned_doc_url",
        text: "Unsigned Agreement",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.unsigned_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.unsigned_doc_url)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            "NA"
          ),
      },
      {
        dataField: "agreement_doc_url",
        text: "Signed Agreement",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {order.agreement_doc_url ? (
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.agreement_doc_url)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      },
      {
        dataField: download ? "signer_one_name" : "",
        text: "Signer 1 Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_one.name,
      },
      {
        dataField: download ? "signer_one_email" : "",
        text: "Signer 1 Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_one.email,
      },
      {
        dataField: download ? "signer_one_sign_type" : "",
        text: "Signer 1 Sign Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_one.sign_type,
      },
      {
        dataField: download ? "signer_one_status" : "",
        text: "Signer 1 Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-capitalize">
            {digioResData(row).signer_one.status}
          </div>
        ),
      },
      {
        dataField: download ? "signer_two_name" : "",
        text: "Signer 2 Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_two.name,
      },
      {
        dataField: download ? "signer_two_email" : "",
        text: "Signer 2 Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_two.email,
      },
      {
        dataField: download ? "signer_two_sign_type" : "",
        text: "Signer 2 Sign Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_two.sign_type,
      },
      {
        dataField: download ? "signer_two_status" : "",
        text: "Signer 2 Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-capitalize">
            {digioResData(row).signer_two.status}
          </div>
        ),
      },
      { dataField: "distributor.user_name", text: "User Name", sort: true },
      { dataField: "distributor.phone", text: "Phone", sort: true },
      {
        dataField: "registered_address",
        text: "Registered Address",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.distributor?.distributor_kyc?.registered_address ||
          order?.registered_address,
      },
      { dataField: "notice_period", text: "Notice Period", sort: true },
      {
        dataField: "signatory",
        text: "Authorised Signatory",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.distributor?.distributor_kyc?.signatory || "",
      }
    )
    return cols
  }

  const handleOrderClick = selected => {
    setIsAgreementExists(false)
    setSelectedOption({ id: selected.distributorId, ...selected.distributor })
    setSelectedAgreement(selected)
    setAgreementFormState({
      effectiveDate: selected?.effective_date,
      noticePeriod: selected?.notice_period,
      isAgreementSigned: selected?.is_agreement_signed,
      distributorId: selected?.distributorId,
      billingFrequency: selected?.billing_frequency,
    })
    toggle()
  }

  const setAgreementUser = async e => {
    if (e.id !== selectedOption?.id) {
      setLoading(true)
      try {
        const data = await axiosInstance.get(
          `/distributor-agreement?distributorId=${e.id}`
        )
        const selected = data?.data?.data?.[0]
        setSelectedOption(e)
        if (selected) {
          setIsAgreementExists(true)
          toast.error("Agreement already exists")
          if (e?.status == "SIGNED") {
            toast.error("Agreement Already Signed")
          }
        } else {
          setIsAgreementExists(false)
        }
        setLoading(false)
      } catch (error) {
        toast.error(error)
        setLoading(false)
      }
    }
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedOption?.id) {
      toast.error("select a user")
      return false
    }
    setLoading(true)
    const distributorAgreementData = {
      effective_date: agreementFormState.effectiveDate,
      notice_period: agreementFormState.noticePeriod,
      billing_frequency: agreementFormState.billingFrequency,
      distributorId: selectedOption?.id,
      status: selectedAgreement?.status || "CREATED",
    }
    try {
      const response = selectedAgreement?.id
        ? await axiosInstance.patch(
            `distributor-agreement/${selectedAgreement?.id}`,
            distributorAgreementData
          )
        : await axiosInstance.post(
            `distributor-agreement`,
            distributorAgreementData
          )
      if (response) {
        toast.success(
          `Agreement ${
            selectedAgreement?.id ? "edited" : "created"
          } successfully`
        )
        const pdfResponse = await axiosInstance.post(
          "/generate-distributor-pdf",
          {
            type: "agreement",
            distributorAgreementId: response?.data?.id,
          }
        )
        if (pdfResponse) {
          toast.success("PDF generated successfully!")
        }
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
      masterData()
      toggle()
    }

    const usersRes = await distributorUsers()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      const distributors = users?.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
      // let currentUserInv = users?.data.find(
      //   user => user?.id == selectedOption?.id
      // )
      // setSelectedOption(currentUserInv)
    } else {
      toast.error("something went wrong")
    }
    setLoading(false)
  }
  const toggle = () => {
    setModal(!modal)
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }

  const handleUploadAgreement = async () => {
    const updateDoc = {
      agreement_doc_url: agreementDocUrl,
      is_agreement_signed: true,
      status: "SIGNED",
    }
    try {
      const response = await axiosInstance.patch(
        `distributor-agreement/${finalSelectedRows[0]?.id}`,
        updateDoc
      )
      if (response) {
        toast.success("Successfully Updated")
        masterData()
        setFinalSelectedRows([])
        toggleUploadModal()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  // const handleDeleteOrder = async order => {
  //   setLoading(true)
  //   var r = confirm(`Are you sure want to delete this user`)
  //   if (r == true) {
  //     try {
  //       const response = await axiosInstance.delete(
  //         `distributor-agreement/${order.id}`
  //       )
  //       if (response) {
  //         masterData()
  //         toast.success("Successfully Deleted")
  //       }
  //     } catch (error) {
  //       toast.error(error.message)
  //     }
  //   }
  //   setLoading(false)
  // }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async () => {
    try {
      setLoading(true)

      const fileName = "Distributor Agreement"

      const res = await axiosInstance.get(
        `/distributor-agreement?$sort[created_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = AgreementColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item.legal_entity_name = item?.distributor?.distributor_kyc?.name
          const digioRes = digioResData(item)
          item.signer_one_name = digioRes.signer_one.name
          item.signer_one_email = digioRes.signer_one.email
          item.signer_one_sign_type = digioRes.signer_one.sign_type
          item.signer_one_status = digioRes.signer_one.status

          item.signer_two_name = digioRes.signer_two.name
          item.signer_two_email = digioRes.signer_two.email
          item.signer_two_sign_type = digioRes.signer_two.sign_type
          item.signer_two_status = digioRes.signer_two.status
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleInitiateAgreement = async () => {
    setIsAgreementExists(false)
    setSelectedAgreement({})
    setLoading(true)
    setSelectedOption({})
    setAgreementFormState({})
    setLoading(false)
    toggle()
  }

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "effectiveDate":
        setAgreementFormState({
          ...agreementFormState,
          effectiveDate: value,
        })
        break
      case "noticePeriod":
        setAgreementFormState({
          ...agreementFormState,
          noticePeriod: value < 0 ? Math.abs(value) : value,
        })
        break
      case "isAgreementSigned":
        setAgreementFormState({
          ...agreementFormState,
          isAgreementSigned: value,
        })
        break
      case "billingFrequency":
        setAgreementFormState({
          ...agreementFormState,
          billingFrequency: value,
        })
        break
      default:
        break
    }
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", finalSelectedRows[0]?.distributorId)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=distributor_agreement_docs&userId=${finalSelectedRows[0]?.distributorId}`,
          formData
        )
        if (response) {
          setAgreementDocUrl(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const handleSendForSigning = async (e, values) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(
        `/digio-signing-request?type=distributor_agreement`,
        {
          distributorAgreementId: selectedAgreement.id,
          defaultSignatory: {
            name: values.name,
            email: values.email,
          },
          signer_one_sign_type: values.signer_one_sign_type || "AADHAAR",
          signer_two_sign_type: values.signer_two_sign_type || "AADHAAR",
        }
      )
      if (response) {
        toast.success("Sent for sign")
        masterData()
        toggleSendForSignModal()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleMultipleId = type => {
    if (!finalSelectedRows.length) {
      toast.error("Please select a row")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one row")
      return
    }

    switch (type) {
      case "upload":
        if (finalSelectedRows[0].status == "SIGNED") {
          toast.error("Agreement already signed")
          break
        }
        toggleUploadModal()

        break
      case "billing_frequency":
        setSelectedBillingFrequency(finalSelectedRows[0].billing_frequency)
        toggleBillingFrequencyModal()
        break
      default:
        break
    }
  }

  const handleUpdateBillingFrequency = async (e, values) => {
    try {
      setLoading(true)
      const res = await axiosInstance.patch(
        `/distributor-agreement/${finalSelectedRows[0].id}`,
        {
          billing_frequency: selectedBillingFrequency,
        }
      )
      await masterData()
      setFinalSelectedRows([])
      toggleBillingFrequencyModal()
      toast.success("Updated successfully")
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Distributor Agreement" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleInitiateAgreement}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => handleMultipleId("billing_frequency")}
                      style={{ marginRight: "3px" }}
                      disabled={finalSelectedRows?.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1"></i> Update Billing
                      Frequency
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => handleMultipleId("upload")}
                      style={{ marginRight: "3px" }}
                      disabled={finalSelectedRows?.length != 1}
                    >
                      <i className="mdi mdi-arrow-up-bold-circle"></i> Upload
                      Signed Agreement
                    </Button>
                  )}

                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={downloadData}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={AgreementColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Status</label>
                              <ReactSelect
                                users={agreementStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "status")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField={(row, index) => index}
                              bordered={false}
                              striped={false}
                              selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              columns={AgreementColumns()}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={uploadModal}
                              toggle={toggleUploadModal}
                            >
                              <ModalHeader toggle={toggleUploadModal} tag="h4">
                                Upload Signed Agreement
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleUploadAgreement}>
                                  <Row
                                    form
                                    style={{
                                      color: "grey",
                                    }}
                                  >
                                    <Col className="col-12">
                                      <div className="mt-3">
                                        {loading && <Loader />}
                                        <AvField
                                          name="agreementDocUrl"
                                          label="Upload"
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please select a file"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={handleFileUpload}
                                        ></AvField>
                                        <p className="mt-3">
                                          {agreementDocUrl
                                            ? `File uploaded: ${agreementDocUrl
                                                .split("/")
                                                .pop()}`
                                            : ""}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-3"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          SAVE
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>

                            <Modal
                              isOpen={billingFrequencyModal}
                              toggle={toggleBillingFrequencyModal}
                            >
                              <ModalHeader
                                toggle={toggleBillingFrequencyModal}
                                tag="h4"
                              >
                                Update Billing Frequency
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={handleUpdateBillingFrequency}
                                >
                                  <Row form>
                                    <div className="mb-3">
                                      <AvField
                                        name="billing_frequency"
                                        label="Billing Frequency"
                                        type="select"
                                        className="form-select"
                                        errorMessage="Invalid value"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={selectedBillingFrequency}
                                        onChange={e => {
                                          setSelectedBillingFrequency(
                                            e.target.value
                                          )
                                        }}
                                      >
                                        <option disabled value="">
                                          Select
                                        </option>
                                        {billingFrequencyEnums.map(item => (
                                          <option
                                            value={item.enum}
                                            key={item.enum}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                      </AvField>
                                    </div>

                                    <Row>
                                      <Col>
                                        <div className={"text-end mt-3"}>
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={loading}
                                          >
                                            SUBMIT
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>

                            <Modal
                              isOpen={cancelAgreementModal}
                              toggle={toggleCancelAgreementModal}
                            >
                              <ModalHeader
                                toggle={toggleCancelAgreementModal}
                                tag="h4"
                              >
                                Confirmation
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleCancelAgreement}>
                                  <Row>
                                    <h5>
                                      The Agreement has already been sent for
                                      Sign.
                                    </h5>
                                    <p className="font-size-15">
                                      {" "}
                                      {selectedAgreement?.webhook_data ? (
                                        <>
                                          Signer 1:{" "}
                                          <b>
                                            {
                                              selectedAgreement?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[0]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAgreement?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[0]?.status
                                            )}
                                          </b>
                                          <br />
                                          <br />
                                          Signer 2:{" "}
                                          <b>
                                            {
                                              selectedAgreement?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[1]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAgreement?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[1]?.status
                                            )}
                                          </b>
                                        </>
                                      ) : (
                                        <>
                                          Signer 1:{" "}
                                          <b>
                                            {
                                              selectedAgreement
                                                ?.signing_request_data
                                                ?.signing_parties[0]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAgreement
                                                ?.signing_request_data
                                                ?.signing_parties[0]?.status
                                            )}
                                          </b>
                                          <br />
                                          <br />
                                          Signer 2:{" "}
                                          <b>
                                            {
                                              selectedAgreement
                                                ?.signing_request_data
                                                ?.signing_parties[1]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAgreement
                                                ?.signing_request_data
                                                ?.signing_parties[1]?.status
                                            )}
                                          </b>
                                        </>
                                      )}
                                    </p>
                                    <div className="d-flex gap-1 my-3">
                                      <AvField
                                        name="cancel_consent"
                                        type="checkbox"
                                        id="cancelConsent"
                                        checked={cancelConsent}
                                        onChange={e =>
                                          setCancelConsent(e.target.checked)
                                        }
                                      />{" "}
                                      <label
                                        className="mb-0 font-size-14 text-muted"
                                        htmlFor="cancelConsent"
                                      >
                                        I want to cancel this sign request
                                      </label>
                                    </div>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <div className="d-flex gap-2 justify-content-end">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setCancelAgreementModal(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!cancelConsent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {selectedAgreement?.id
                                  ? "Edit Distributor Agreement"
                                  : "Distributor Agreement"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row
                                    form
                                    style={{
                                      color: "grey",
                                    }}
                                  >
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <label>Distributor</label>
                                        {selectedOption.id ? (
                                          <ReactSelect
                                            users={users}
                                            selectedOption={{
                                              user_name:
                                                selectedOption?.distributor_kyc
                                                  ?.name,
                                              email: selectedOption.email,
                                              phone: selectedOption.phone,
                                            }}
                                            isDisabled={
                                              selectedAgreement?.id
                                                ? true
                                                : false
                                            }
                                            setSelectedOption={setAgreementUser}
                                          />
                                        ) : (
                                          <ReactSelect
                                            users={users}
                                            setSelectedOption={setAgreementUser}
                                            isDisabled={
                                              selectedAgreement?.id
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="effectiveDate"
                                          label="Effective Date"
                                          type="date"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          disabled={isAgreementExists}
                                          value={
                                            agreementFormState?.effectiveDate ||
                                            ""
                                          }
                                          onChange={handleOnChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="noticePeriod"
                                          label="Notice Period"
                                          type="number"
                                          errorMessage="Invalid Value"
                                          min={0}
                                          disabled={isAgreementExists}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            agreementFormState.noticePeriod ||
                                            ""
                                          }
                                          onChange={handleOnChange}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="billingFrequency"
                                          label="Billing Frequency"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid value"
                                          disabled={isAgreementExists}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            agreementFormState.billingFrequency ||
                                            ""
                                          }
                                          onChange={handleOnChange}
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          {billingFrequencyEnums.map(item => (
                                            <option
                                              value={item.enum}
                                              key={item.enum}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-2"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={
                                            loading || isAgreementExists
                                          }
                                        >
                                          SAVE
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={sendForSignModal}
                              toggle={toggleSendForSignModal}
                            >
                              <ModalHeader
                                toggle={toggleSendForSignModal}
                                tag="h4"
                              >
                                Send for Sign
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleSendForSigning}>
                                  <h5 className="mb-3">
                                    Please verify the details of the signing
                                    parties before sending it for sign.
                                  </h5>
                                  <Row>
                                    <Col>
                                      <div className="row">
                                        <h5 className="mb-3">Signer 1</h5>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="name"
                                            label="Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={defaultSignatory.name}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="email"
                                            label="Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={defaultSignatory.email}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="signer_one_sign_type"
                                            label="Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                          >
                                            <option
                                              value="AADHAAR"
                                              selected={true}
                                            >
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <h5 className="mb-3">Signer 2</h5>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="distributor_name"
                                            label="Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              selectedAgreement?.distributor
                                                ?.distributor_kyc?.signatory ||
                                              selectedAgreement?.distributor
                                                ?.distributor_kyc?.name ||
                                              ""
                                            }
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="distributor_email"
                                            label="Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              selectedAgreement?.distributor
                                                ?.email || ""
                                            }
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="signer_two_sign_type"
                                            label="Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                          >
                                            <option
                                              value="AADHAAR"
                                              selected={true}
                                            >
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <div className="d-flex gap-1 mb-3">
                                          <AvField
                                            name="consent"
                                            type="checkbox"
                                            id="consent"
                                            checked={consent}
                                            onChange={e =>
                                              setConsent(e.target.checked)
                                            }
                                          />{" "}
                                          <label
                                            className="mb-0 font-size-14 text-muted"
                                            htmlFor="consent"
                                          >
                                            I have reviewed the document and
                                            verified the signer details
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="justify-content-end mt-2">
                                    <div className="d-flex justify-content-end gap-2">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setSendForSignModal(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!consent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DistributorAgreement
