import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AccessId, distributorInvoiceStatus } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData } from "constants/common"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { investmentData } from "constants/common"
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DistributorInvoice = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [investmentFilter, setInvestmentFilter] = useState({})
  const [statusFilter, setStatusFilter] = useState({})
  const [investmentList, setInvestmentList] = useState([])

  const [userFilter, setUserFilter] = useState("")

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState()
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" })
  const [finalSelectedRows, setFinalSelectedRows] = useState([])

  const [selectedStatus, setSelectedStatus] = useState({})
  const [updateStatusModal, setUpdateStatusModal] = useState(false)
  const [sendRequestCheckbox, setSendRequestCheckbox] = useState(false)
  const [sendRequestModal, setSendRequestModal] = useState(false)

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${statusFilter?.id ? `&status=${statusFilter.id}` : ""}`

  const masterData = async () => {
    setLoading(true)

    const getUrl = `distributor-invoice?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        setData(response.data?.data || response.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }
  const getFiltersDataList = async () => {
    try {
      setLoading(true)

      const invResponse = await investmentData("Approved", "Closed", "Exited")
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFiltersDataList()
  }, [])
  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter, statusFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleDelete = async item => {
    var r = confirm(`Are you sure want to delete`)
    if (r) {
      try {
        setLoading(true)
        await axiosInstance.delete(`distributor-invoice/${item.id}`)
        masterData()
        toast.success("Deleted successfully")
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const Columns = () => {
    let col = [
      // {
      //   dataField: "action",
      //   isDummyField: true,
      //   text: "Action",
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, order) => (
      //     <>
      //       <div className="d-flex gap-3">
      //         {accessRestriction === "SuperAdmin" &&
      //         order.status == "GENERATED" ? (
      //           <Link
      //             className="text-danger"
      //             onClick={() => handleDelete(order)}
      //           >
      //             <i
      //               className="mdi mdi-delete font-size-18"
      //               id="deletetooltip"
      //             />
      //             <UncontrolledTooltip placement="top" target="deletetooltip">
      //               Delete
      //             </UncontrolledTooltip>
      //           </Link>
      //         ) : (
      //           "NA"
      //         )}
      //       </div>
      //     </>
      //   ),
      // },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.name",
        text: "Legal Entity Name",
        sort: true,
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },

      {
        dataField: "distributor.user_name",
        text: "Distributor User Name",
        sort: true,
      },
      { dataField: "distributor.email", text: "Distributor Email", sort: true },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>
            {distributorInvoiceStatus.find(item => item.id == cellContent)
              ?.statusText || cellContent}
          </div>
        ),
      },
      {
        dataField: "sample_invoice",
        text: "System Generated Proforma Invoice",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.sample_invoice ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.sample_invoice)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            ""
          ),
      },
      {
        dataField: "uploaded_invoice",
        text: "Uploaded Invoice",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.uploaded_invoice ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.uploaded_invoice)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            ""
          ),
      },

      {
        dataField: "invoice_date",
        text: "Invoice Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          cellContent ? handleValidDate(row.invoice_date) : null,
      },
      {
        dataField: "invoice_number",
        text: "Invoice Number",
        sort: true,
      },

      // {
      //   dataField: "start_date",
      //   text: "Start Date",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, row) => handleValidDate(cellContent),
      // },
      // {
      //   dataField: "end_date",
      //   text: "End Date",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, row) => handleValidDate(row.end_date),
      // },
      {
        dataField: "start_date",
        text: "Billing Period",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          moment(cellContent).format("DD MMM YYYY") +
          " - " +
          moment(row.end_date).format("DD MMM YYYY"),
      },
      {
        dataField: "fee_type",
        text: "Invoice Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{cellContent.replaceAll("_", " ")}</div>
        ),
      },
      {
        dataField: "capital_raised",
        text: "Capital Raised",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(row.capital_raised),
      },
      {
        dataField: "calculated_fee_percent",
        text: "Fee %",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => calculateFeePercent(row),
      },
      {
        dataField: "fee_amount",
        text: "Fee Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(row.fee_amount),
      },
      {
        dataField: "cgst",
        text: "CGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "sgst",
        text: "SGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "igst",
        text: "IGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "",
        text: "Total Tax Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(row.cgst + row.igst + row.sgst),
      },
      {
        dataField: "",
        text: "Invoice Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(row.cgst + row.igst + row.sgst + row.fee_amount),
      },

      { dataField: "distributor.phone", text: "Distributor Phone", sort: true },
      {
        dataField: "distributor.distributor_kyc.pan_number",
        text: "Distributor PAN Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.gst_number",
        text: "GST Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.account_name",
        text: "Bank Account Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.bank_name",
        text: "Bank Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.branch_name",
        text: "Branch Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.account_number",
        text: "Bank Account Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.ifsc_code",
        text: "IFSC Code",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.created_at),
      },
    ]
    return col
  }

  const calculateFeePercent = row => {
    const percent = (row.fee_amount / row.capital_raised) * 100
    return Number(percent).toFixed(2)
  }
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const toggle = () => {
    setIsModalOpen(!isModalOpen)
  }
  const sendRequestToggle = () => {
    setSendRequestModal(!sendRequestModal)
  }

  const updateStatusModalToggle = () => {
    setUpdateStatusModal(!updateStatusModal)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "status":
        setStatusFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const downloadData = async () => {
    try {
      const fileName = "Distributor Invoice"
      setLoading(true)
      const url = `distributor-invoice?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = Columns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(element => {
          element.calculated_fee_percent = calculateFeePercent(element)
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleGenerateRequest = async () => {
    setSelectedProject()
    setDateRange({ startDate: "", endDate: "" })
    toggle()
  }
  const handleValidSubmit = async (e, values) => {
    try {
      if (!selectedProject?.id) {
        toast.error("Select a project")
        return
      }
      if (!dateRange?.startDate) {
        toast.error("Select start date")
        return
      }
      if (!dateRange.endDate) {
        toast.error("Select end date")
        return
      }

      setLoading(true)
      const obj = {
        start_date: moment(dateRange.startDate).format("yyyy-MM-DD"),
        end_date: moment(dateRange.endDate).format("yyyy-MM-DD"),
        investmentId: selectedProject.id,
      }
      const res = await axiosInstance.post(`/distributor-invoice`, obj)
      toast.success("Generated Successfully")
      toggle()
      masterData()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleMultipleIds = async type => {
    if (!finalSelectedRows.length) {
      toast.error("Please select atleast a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return
    }
    switch (type) {
      case "sendRequest":
        sendRequestToggle()
        setSendRequestCheckbox(false)
        setUpdateStatusModal(false)
        break

      case "updateStatus":
        setSelectedStatus({})
        updateStatusModalToggle()

        break

      default:
        break
    }
  }

  const handleUpdateStatus = async (e, values) => {
    try {
      if (!selectedStatus?.id) {
        toast.error("Select a status")
        return
      }
      setLoading(true)
      const res = await axiosInstance.patch(
        `/distributor-invoice/${finalSelectedRows[0].id}`,
        {
          status: selectedStatus?.id,
          re_submit_notes: values?.re_submit_notes || undefined,
        }
      )
      toast.success("Status updated successfully")
      setFinalSelectedRows([])

      updateStatusModalToggle()
      masterData()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSendInvoiceRequest = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.patch(
        `/distributor-invoice/${finalSelectedRows[0].id}`,
        {
          status: "PENDING",
        }
      )
      toast.success("Invoice request sent successfully")
      setFinalSelectedRows([])

      sendRequestToggle()
      masterData()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const minDate = () => {
    const date = new Date()
    const month = date.getMonth() + 1
    // return new Date(`${date.getFullYear()}-${month-1}-01`)

    // if (month == 3) {
    //   return new Date(`${date.getFullYear()}-${month}-01`)
    // }

    return new Date(`${date.getFullYear()}-${month - 1}-16`)
  }

  const disableDates = date => {
    const today = new Date()
    const currentMonth = today.getMonth()
    const currentYear = today.getFullYear()

    const dateMonth = date.getMonth()
    const dateYear = date.getFullYear()

    if (dateYear === currentYear && dateMonth === currentMonth - 1) {
      return true
    }

    const day = date.getDate()
    // Disable dates from 27th to 31st in the current month
    return day < 27
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Distributor Invoice" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {/* {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleGenerateRequest}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-plus me-1" /> Generate Invoice
                      Request
                    </Button>
                  )} */}
                  {/* {accessRestriction === "SuperAdmin" && (
                    <Button
                      type="button"
                      color="danger"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("sendRequest")}
                      disabled={
                        !(
                          finalSelectedRows.length == 1 &&
                          finalSelectedRows[0]?.status == "GENERATED"
                        )
                      }
                    >
                      <i className="mdi mdi-email me-1" /> Request Invoice
                    </Button>
                  )} */}

                  {accessRestriction === "SuperAdmin" && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("updateStatus")}
                      disabled={
                        !(
                          finalSelectedRows.length == 1 &&
                          finalSelectedRows[0]?.status != "PAID"
                        )
                      }
                    >
                      <i className="mdi mdi-pencil me-1" /> Update Status
                    </Button>
                  )}
                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={downloadData}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={Columns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="col-auto">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search Name, Email, Phone`}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Status</label>
                              <ReactSelect
                                users={distributorInvoiceStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "status")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={Columns()}
                              data={data}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={sendRequestModal} toggle={sendRequestToggle}>
            <ModalHeader toggle={sendRequestToggle} tag="h4">
              Send Invoice Upload Request
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleSendInvoiceRequest}>
                <Row form>
                  <p className="font-size-15 text-muted mb-3 d-block">
                    Project Name:{" "}
                    <b>{finalSelectedRows[0]?.investment?.project_name}</b>
                    <br />
                    Invoice Date:{" "}
                    <b>{handleValidDate(finalSelectedRows[0]?.invoice_date)}</b>
                    <br />
                    Legal Entity Name:{" "}
                    <b>
                      {finalSelectedRows[0]?.distributor?.distributor_kyc?.name}
                    </b>
                  </p>
                  <div className="d-flex gap-1">
                    <input
                      type="checkbox"
                      id="requestConsent"
                      checked={sendRequestCheckbox}
                      onChange={e => setSendRequestCheckbox(e.target.checked)}
                    />{" "}
                    <label
                      className="mb-0 font-size-14 text-muted"
                      htmlFor="requestConsent"
                    >
                      I have verified the details of invoice and the annexure.
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className={"text-end mt-2"}>
                      <button
                        type="submit"
                        className="btn btn-success save-user mt-2"
                        disabled={loading || !sendRequestCheckbox}
                      >
                        Send Request
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal isOpen={isModalOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Generate Invoice Request
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidSubmit}>
                <Row form>
                  <div className="mb-3">
                    <label>Project Name</label>
                    <ReactSelect
                      users={investmentList}
                      setSelectedOption={setSelectedProject}
                      selectedOption={selectedProject}
                      multiOptionLabel={true}
                      optionLabelKeys={["project_name"]}
                      isClearable={true}
                    />
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <AvGroup>
                        <Label for="date">Select Start Date</Label>
                        <DatePicker
                          selected={dateRange.startDate}
                          placeholderText="dd-mm-yyyy"
                          showIcon={true}
                          icon="fa fa-calendar"
                          onChange={date => {
                            setDateRange({
                              ...dateRange,
                              startDate: date,
                            })
                          }}
                          minDate={minDate()}
                          maxDate={new Date()}
                          filterDate={disableDates}
                          dateFormat="dd-MM-yyyy"
                          className="p-2 rounded border-1 w-100"
                        />
                        <AvFeedback>Please select a valid date</AvFeedback>
                      </AvGroup>
                    </div>
                    <div className="mb-3 col-md-6">
                      <AvGroup>
                        <Label for="date">Select End Date</Label>
                        <DatePicker
                          selected={dateRange.endDate}
                          placeholderText="dd-mm-yyyy"
                          showIcon={true}
                          icon="fa fa-calendar"
                          onChange={date =>
                            setDateRange({
                              ...dateRange,
                              endDate: date,
                            })
                          }
                          filterDate={disableDates}
                          minDate={minDate()}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="p-2 rounded border-1 w-100"
                        />
                        <AvFeedback>Please select a valid date</AvFeedback>
                      </AvGroup>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className={"text-end mt-2"}>
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={loading}
                      >
                        Generate
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal isOpen={updateStatusModal} toggle={updateStatusModalToggle}>
            <ModalHeader toggle={updateStatusModalToggle} tag="h4">
              Update Status
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleUpdateStatus}>
                <Row form>
                  <div className="mb-3">
                    <label>Status</label>
                    <ReactSelect
                      users={distributorInvoiceStatus.filter(
                        item =>
                          item.id !== "PENDING" &&
                          item.id != "SUBMIT" &&
                          item.id != "GENERATED"
                      )}
                      setSelectedOption={setSelectedStatus}
                      multiOptionLabel={true}
                      optionLabelKeys={["statusText"]}
                    />
                  </div>
                  {selectedStatus?.id == "RE_SUBMIT" ? (
                    <div className="mb-3">
                      <AvField
                        name="re_submit_notes"
                        label="Notes"
                        type="textarea"
                        errorMessage="Invalid Value"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  ) : null}
                </Row>
                <Row>
                  <Col>
                    <div className={"text-end mt-2"}>
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={loading}
                      >
                        Update Status
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DistributorInvoice)
